import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import env from "react-dotenv";
import WebApp from "@twa-dev/sdk";

function App() {
  const [list, setList] = useState([]);

  const initData = WebApp.initData
  console.log(initData)
  //разворачиваем на всю высоту
  if (!WebApp.isExpanded) {
    WebApp.expand();
    /*
    WebApp.MainButton.show();
    WebApp.MainButton.hide();
    */
  }

  WebApp.setHeaderColor("#000");
  WebApp.setBackgroundColor("#000")

  const methodPay = async (data, status) => {

    if (data) {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/orders_admin_set/${data.id}/${status}`
      )
        .then(async response => {
          const newList = list.filter((item) => item.id !== data.id);
          setList(newList);
          alert('Готово, элемент скрыт!')
          const data = await response.json()
          console.log(data.data)
        })
        .catch(error => console.error(error));
    }
  }

  const loadTransaction = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/orders/1`
    )
      .then(async response => {
        const data = await response.json()
        console.log(data.data)
        setList(data.data)
      })
      .catch(error => console.error(error));

    // Обновим состояние
    //setList(response);
  }
  useEffect(() => {
    loadTransaction()
  }, [])


  const Item = (item) => {
    return (
      <>

        <figure className="bg-slate-100 rounded-xl my-5 p-8 " id={`item_${item.data.id}`}>
          <img className="w-full h-auto rounded-xl  mx-auto" src={`${process.env.REACT_APP_API_URL}/api/photo/${item.data.photo}`} alt="" />
          <div className="pt-6 space-y-4">
            <blockquote>
              <p className="text-lg font-medium">№{item.data.id}</p>
              <p className="text-lg font-medium">Сумма: {item.data.id}</p>
            </blockquote>
            <figcaption>
              <div>
                Сумма: {item.data.sum} RUB<br />
                Тип платежа: {item.data.provider}<br />
                ФИО: {item.data.fio}<br />
                Телефон: {item.data.phone}<br />
                Номер дома: {item.data.address}<br />
                Аккаунт: <a href={`https://t.me/${item.data.user_name}`} >@{item.data.user_name}</a><br />
              </div>
            </figcaption>
            <div className="flex space-x-4 mb-6 text-sm font-medium">
              <div className="flex-auto flex space-x-4">
                <button className="h-10 px-6 font-semibold rounded-md bg-black text-white" onClick={() => methodPay(item.data, true)} type="submit">
                  Подтвердить
                </button>
                <button onClick={() => methodPay(item.data, false)} className="h-10 px-6 font-semibold rounded-md border border-slate-200 text-slate-900" type="button">
                  Отклонить
                </button>
              </div>

            </div>
          </div>
        </figure>

      </>
    )
  }

  return (
    <div className='app ' id='app'>
      <div className='max-w-[760px] mx-auto'>
        {list.length < 1 ?
          'Нет данных'
          :
          
            list.map((data) => {
              return (
                <Item data={data} key={data.id} />
              )
            }
            )
          
        }
      </div>

    </div>


  );
}

export default App;
